<script>
import { jsonRequestHeader } from '../shared/headers'
import { ModalMixin } from '../shared/modal_mixin'

export default {
  mixins: [ModalMixin],
  props: {
    currentUser: { type: Object, required: false },
    shirtPrice: { type: Number, default: 9.99 },
    postagePrice: { type: Number, default: 2 },
    postPayPrice: { type: Number, default: 2 },
    maxInvitedFriends: { type: Number, default: 5 }
  },
  data () {
    return {
      modalViewState: 'closed',
      isOrderPlaced: false,
      isOrderButtonClicked: false,
      itemId: 0,
      basketItems: [],
      address: null,
      userEmail: null,
      userName: null,
      paymentMethod: 'prepay',
      collectionAddress: null,
      invitedFriends: [],
      isShoppingCartOpen: false,
      sizeSelections: {
        wblack: 's',
        wwhite: 's',
        mblue: 'm',
        mgrey: 'm'
      },
      email: null,
      isEmailValid: null,
      inviteErrorMessage: null,
      orderError: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.temporaryEmailSufixes = [
        '@mvrht.net',
        '@kopqi.com',
        '@20mail.eu',
        '@fxe.us',
        '@vmani.com',
        '@sharklasers.com',
        '@10minutemail.co.uk',
        '@my10minutemail.com',
        '@emlpro.com',
        '@mailinator.com',
        '@mt2015.com',
        '@TempEMail.net',
        '@tempinbox.com'
      ]
    })
  },
  computed: {
    price () {
      return this.shirtPrice * this.basketItems.length
    },
    postageCosts () {
      if (this.basketItems.length > 0) {
        let costs = 0

        if (this.paymentMethod !== 'collection') {
          costs += this.postagePrice
        }

        if (this.paymentMethod === 'postpay') {
          costs += this.postPayPrice
        }

        return costs
      } else {
        return 0
      }
    },
    totalPrice () {
      if (this.basketItems.length > 0) {
        let totalPrice = this.price + this.postageCosts

        // Deduct 1 EUR for every invited friend up to 5 EUR
        totalPrice -= Math.min(this.invitedFriends.length, this.maxInvitedFriends)

        return totalPrice
      } else {
        return 0
      }
    },
    totalPriceText () {
      let discount = Math.min(this.invitedFriends.length, this.maxInvitedFriends)

      if (this.invitedFriends.length > 0) {
        return `${this.totalPrice.toFixed(2)}€ (${this.translations.shop.modal.discount} ${discount}€)`
      } else {
        return `${this.totalPrice.toFixed(2)}€`
      }
    },
    inviteButtonClass () {
      return {
        'big-button--disabled': !this.isInviteEnabled
      }
    },
    isInviteEnabled () {
      return this.isEmailValid !== false && this.email != null && this.email != ''
    },
    showBasketEmptyError () {
      return this.isOrderButtonClicked && this.basketItems.length === 0
    },
    showEmailError () {
      return this.isOrderButtonClicked && !this.userEmail && !this.currentUser
    },
    showNameError () {
      return this.isOrderButtonClicked && !this.userName && !this.currentUser
    },
    showAddressError () {
      return this.isOrderButtonClicked && !this.address
    },
    showCollectionAddressError () {
      return this.isOrderButtonClicked && this.paymentMethod === 'collection' && !this.collectionAddress
    },
    isOrderValid () {
      return this.basketItems.length > 0 &&
          this.totalPrice > 0 &&
          this.address &&
          (this.collectionAddress || this.paymentMethod !== 'collection') &&
          (this.currentUser || (this.userEmail && this.userName))
    }
  },
  methods: {
    incrementItemId () {
      this.itemId = this.itemId + 1
    },
    selectSize (shirtType, size) {
      this.sizeSelections[shirtType] = size
    },
    addItem (itemType) {
      let gender = itemType.charAt(0)
      let color = itemType.substr(1)

      let item = {
        id: this.itemId,
        gender: gender,
        size: this.sizeSelections[itemType],
        color: color
      }
      this.incrementItemId()

      this.basketItems.push(item)
    },
    removeItem (id) {
      this.basketItems = this.basketItems.filter((item) => item.id !== id)
    },
    getItemDescription (item) {
      return `${this.translations.shop.modal.gender}: ${this.translations.shop.modal[item.gender]}, ${this.translations.shop.modal.color}: ${this.translations.shop.modal[item.color].toLowerCase()}, ${this.translations.shop.modal.size}: ${item.size.toUpperCase()}`
    },
    validateEmail (email) {
      let isEmailValid = true

      if (email && email != '') {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isInvalidEmail = !regex.test(email)
        let isAlreadyInvited = this.invitedFriends.includes(email)
        let isTemporaryEmail = this.temporaryEmailSufixes.reduce((isTemporary, tempEmailSufix) => {
          return isTemporary || email.endsWith(tempEmailSufix)
        }, false)

        if (isTemporaryEmail) {
          this.inviteErrorMessage = this.translations.shop.modal.temporary_email
        }

        if (isAlreadyInvited) {
          this.inviteErrorMessage = this.translations.shop.modal.invited_user_already_invited
        }

        if (isInvalidEmail) {
          this.inviteErrorMessage = this.translations.shop.modal.invalid_email
        }

        isEmailValid = !isInvalidEmail && !isAlreadyInvited && !isTemporaryEmail
      }

      this.isEmailValid = isEmailValid

      if (isEmailValid) {
        this.inviteErrorMessage = null
      }
    },
    inviteFriend () {
      if (this.isInviteEnabled) {
        let requestBody = {
          emails: [this.email]
        }

        if (this.currentUser) {
          requestBody.user_id = this.currentUser.id
        }

        this.$http.post('/invitations', requestBody, jsonRequestHeader).then(response => {
          if (response.status === 201) {
            if (response.body.rejected.length > 0) {
              this.inviteErrorMessage = this.translations.shop.modal.invited_user_already_exists
            } else {
              this.invitedFriends.push(this.email)
              this.email = null
            }
          } else {
            this.inviteErrorMessage = this.translations.shop.modal.invite_error
          }
        }, error => {
          this.inviteErrorMessage = error.message
        });
      }
    },
    toggleShoppingCart (open) {
      if (open == null) {
        this.isShoppingCartOpen = !this.isShoppingCartOpen
      } else {
        this.isShoppingCartOpen = open
      }
    },
    placeOrder () {
      if (this.isOrderValid) {
        let requestBody = {
          address: this.address,
          payment: this.paymentMethod,
          invited_friends: this.invitedFriends.length,
          items: this.basketItems.map((i) => {
            return {
              gender: i.gender,
              size: i.size,
              color: i.color
            }
          })
        }

        if (this.currentUser) {
          requestBody.user_id = this.currentUser.id
        } else {
          requestBody.user_email = this.userEmail
          requestBody.user_name = this.userName
        }

        if (this.collectionAddress) {
          requestBody.collection_address = this.collectionAddress
        }

        this.$http.post('/orders', requestBody, jsonRequestHeader).then(response => {
          this.isOrderPlaced = true
          if (response.status !== 201) {
            this.orderError = response.body.message
            throw new Error(response.body.message)
          }
        }, error => {
          this.isOrderPlaced = true
          let errorMessage = error.body.message || error.body.error
          this.orderError = errorMessage
          throw new Error(errorMessage)
        });
      } else {
        this.isOrderButtonClicked = true
      }
    }
  }
}
</script>

<template>
  <div class="modal__container no-click-tracking" :class="modalViewState" id="shop-modal" ref="modalContainer" @click="toggleShoppingCart(false)">
    <div class="modal__form-container" ref="modalFormContainer">
      <div id="shop-close-modal" class="modal__close-button" data-modal-id="shop-modal" :title="translations.close" @click="closeModal">
        <img src="/assets/icons/close_white-55a0f9afd57a1c1d72faab83967e2283a6ec0f7d8b1d57fa412a1760faa9b4f4.svg" />
      </div>

      <br/>

      <div class="modal__heading">
        {{ translations.shop.modal.heading }}

        <div class="modal__shopping-cart-button" id="shop-cart-button" @click.stop="toggleShoppingCart(null)">
          <img src="/assets/icons/shopping_cart-9c445c21db9faeca59b6caf666dca31a99551c548b2132c7bfe15ac55a57f50f.svg" />
          <div class="modal__shopping-cart-items-count">
            {{ basketItems.length }}
          </div>
        </div>
      </div>

      <div class="modal__shopping-cart" v-show="isShoppingCartOpen">
        <div class="modal__shopping-cart-item" v-if="basketItems.length === 0">
          {{ translations.shop.modal.no_items_in_cart }}
        </div>

        <div class="modal__shopping-cart-item" v-for="item, index in basketItems">
          <div class="modal__shopping-cart-item-content">
            {{ `${index + 1}: ${getItemDescription(item)}` }}
          </div>

          <div class="modal__shopping-cart-item-remove" id="shop-cart-item-remove" @click.stop="removeItem(item.id)">
            ×
          </div>
        </div>
      </div>

      <div class="modal__final" v-if="isOrderPlaced">
        <div class="modal__final-error" v-if="orderError">
          <div class="modal__final-icon-container">
            <img src="/assets/icons/error_red-d4e8e8fe757c8077d3f5f5587e961a0013151eec44a443330b54decbccaee905.svg" />
          </div>

          <div class="modal__final-text modal__final-text--error">
            {{ translations.shop.modal.order_error }}
          </div>
        </div>

        <div class="modal__final-success" v-else>
          <div class="modal__final-icon-container">
            <img src="/assets/icons/flying_email-41122fc58e6dec8e3542280f17721e14b59f68cf8625eb79b8f54f1ef0722f2f.svg" />
          </div>

          <div class="modal__final-text modal__final-text--success" v-if="paymentMethod === 'prepay'">
            {{ translations.shop.modal.ordered_success_text }}<br/><br/>
            {{ `${translations.shop.modal.ordered_prepay_text}:` }}<br/>
            {{ this.address }}
          </div>
          <div class="modal__final-text modal__final-text--success" v-if="paymentMethod === 'postpay'">
            {{ translations.shop.modal.ordered_success_text }}<br/><br/>
            {{ `${translations.shop.modal.ordered_postpay_text}:` }}<br/>
            {{ this.address }}
          </div>
          <div class="modal__final-text modal__final-text--success" v-if="paymentMethod === 'collection'">
            {{ translations.shop.modal.ordered_success_text }}<br/><br/>
            {{ `${translations.shop.modal.ordered_collection_text}:` }}<br/>
            {{ this.collectionAddress }}
            {{ translations.shop.modal.ordered_collection_text2 }}<br/>
          </div>
        </div>
      </div>

      <div class="modal__form" v-else>
        <div class="modal__tab-content-container modal__tab-content-container--active">
          <div class="modal__final">
            <div class="modal__shop-items">
              <div class="modal__shop-item">
                <div class="modal__shop-item-image-container">
                  <img class="modal__shop-item-image" src="/assets/shop/woman_black-d20c361156e6ad0f4c802d30036f8208e89853de5c44b53197973d6232dcdf45.jpg" />
                </div>
                <div class="modal__shop-item-price">{{ translations.shop.modal.price }}: {{ shirtPrice }}€</div>
                <div class="modal__shop-item-sizes">
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.wblack === 's' }"
                       id="shop-select-size-women-black-s"
                       @click="selectSize('wblack', 's')"
                  >S</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.wblack === 'm' }"
                       id="shop-select-size-women-black-m"
                       @click="selectSize('wblack', 'm')"
                  >M</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.wblack === 'l' }"
                       id="shop-select-size-women-black-l"
                       @click="selectSize('wblack', 'l')"
                  >L</div>
                </div>
                <div class="modal__shop-item-add-button big-button" id="shop-add-to-card-women-black" @click="addItem('wblack')">
                  {{ translations.shop.modal.add_to_cart }}
                </div>
              </div>

              <div class="modal__shop-item">
                <div class="modal__shop-item-image-container">
                  <img class="modal__shop-item-image" src="/assets/shop/woman_white-b6d33925d406c837ceccfe7dfce1bed38ce0359e7959b60823efbba1d94c913d.jpg" />
                </div>
                <div class="modal__shop-item-price">{{ translations.shop.modal.price }}: {{ shirtPrice }}€</div>
                <div class="modal__shop-item-sizes">
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.wwhite === 's' }"
                       id="shop-select-size-women-white-s"
                       @click="selectSize('wwhite', 's')"
                  >S</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.wwhite === 'm' }"
                       id="shop-select-size-women-white-m"
                       @click="selectSize('wwhite', 'm')"
                  >M</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.wwhite === 'l' }"
                       id="shop-select-size-women-white-l"
                       @click="selectSize('wwhite', 'l')"
                  >L</div>
                </div>
                <div class="modal__shop-item-add-button big-button" id="shop-add-to-card-women-white" @click="addItem('wwhite')">
                  {{ translations.shop.modal.add_to_cart }}
                </div>
              </div>

              <div class="modal__shop-item">
                <div class="modal__shop-item-image-container">
                  <img class="modal__shop-item-image" src="/assets/shop/men_blue-e79fa7828f2944a4c6dbe22fb2e35e246592587ad52d62044d4dd3cc014675ac.jpg" />
                </div>
                <div class="modal__shop-item-price">{{ translations.shop.modal.price }}: {{ shirtPrice }}€</div>
                <div class="modal__shop-item-sizes">
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.mblue === 'm' }"
                       id="shop-select-size-men-blue-m"
                       @click="selectSize('mblue', 'm')"
                  >M</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.mblue === 'l' }"
                       id="shop-select-size-men-blue-l"
                       @click="selectSize('mblue', 'l')"
                  >L</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.mblue === 'xl' }"
                       id="shop-select-size-men-blue-xl"
                       @click="selectSize('mblue', 'xl')"
                  >XL</div>
                </div>
                <div class="modal__shop-item-add-button big-button" id="shop-add-to-card-men-blue" @click="addItem('mblue')">
                  {{ translations.shop.modal.add_to_cart }}
                </div>
              </div>

              <div class="modal__shop-item">
                <div class="modal__shop-item-image-container">
                  <img class="modal__shop-item-image" src="/assets/shop/men_gray-17c1752d6995f60f2bbdf1f0c7fe9d5776ae43c906fa8aac8205edbf8bd19bd2.jpg" />
                </div>
                <div class="modal__shop-item-price">{{ translations.shop.modal.price }}: {{ shirtPrice }}€</div>
                <div class="modal__shop-item-sizes">
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.mgrey === 'm' }"
                       id="shop-select-size-men-grey-m"
                       @click="selectSize('mgrey', 'm')"
                  >M</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.mgrey === 'l' }"
                       id="shop-select-size-men-grey-l"
                       @click="selectSize('mgrey', 'l')"
                  >L</div>
                  <div class="modal__shop-item-button"
                       :class="{ 'modal__shop-item-button--selected': sizeSelections.mgrey === 'xl' }"
                       id="shop-select-size-men-grey-xl"
                       @click="selectSize('mgrey', 'xl')"
                  >XL</div>
                </div>
                <div class="modal__shop-item-add-button big-button" id="shop-add-to-card-men-grey" @click="addItem('mgrey')">
                  {{ translations.shop.modal.add_to_cart }}
                </div>
              </div>
            </div>

            <div class="modal__error-explanation u-horizontal-align-center" v-if="showBasketEmptyError">
              {{ translations.shop.modal.basket_empty_error }}
            </div>

            <div class="modal__section modal__price">
              {{ translations.shop.modal.current_items }}: {{ basketItems.length}}<br/>
              {{ translations.shop.modal.current_price }}: {{ price }}€
            </div>

            <div class="modal__section modal__user-data">
              <div :class="{ 'field_with_errors': showEmailError }" v-if="!currentUser">
                <label class="modal__label">{{ translations.shop.modal.user_email }}<span class='required-asterix'>*</span></label>
                <input class="modal__input-field" type="text" v-model="userEmail" :placeholder="translations.shop.modal.user_email_placeholder" autocomplete="email" />
              </div>
              <div class="modal__error-explanation" v-if="showEmailError">
                {{ translations.shop.modal.email_missing }}
              </div>

              <div :class="{ 'field_with_errors': showNameError }" v-if="!currentUser">
                <label class="modal__label">{{ translations.shop.modal.user_name }}<span class='required-asterix'>*</span></label>
                <input class="modal__input-field" type="text" v-model="userName" :placeholder="translations.shop.modal.user_name_placeholder" />
              </div>
              <div class="modal__error-explanation" v-if="showNameError">
                {{ translations.shop.modal.name_missing }}
              </div>

              <div :class="{ 'field_with_errors': showAddressError }">
                <label class="modal__label">{{ translations.shop.modal.address }}<span class='required-asterix'>*</span></label>
                <input class="modal__input-field" type="text" v-model="address" :placeholder="translations.shop.modal.address_placeholder" autocomplete="address" />
              </div>
              <div class="modal__error-explanation" v-if="showAddressError">
                {{ translations.shop.modal.address_missing }}
              </div>

              <label class="modal__label">{{ translations.shop.modal.payment_option }}</label>
              <select class="modal__input-field" v-model="paymentMethod">
                <option value="prepay">{{ `${translations.shop.modal.prepay} (${this.postagePrice}€)` }}</option>
                <option value="collection">{{ `${translations.shop.modal.collection} (0€)` }}</option>
                <option value="postpay">{{ `${translations.shop.modal.postpay} (${this.postPayPrice + this.postagePrice}€)` }}</option>
              </select>

              <div :class="{ 'field_with_errors': showCollectionAddressError }" v-if="paymentMethod === 'collection'">
                <label class="modal__label">{{ translations.shop.modal.collection_address }}<span class='required-asterix'>*</span></label>
                <select class="modal__input-field" v-model="collectionAddress">
                  <option :value="translations.shop.modal.collection_address_center">{{ translations.shop.modal.collection_address_center }}</option>
                  <option :value="translations.shop.modal.collection_address_vic">{{ translations.shop.modal.collection_address_vic }}</option>
                  <option :value="translations.shop.modal.collection_address_crnuce">{{ translations.shop.modal.collection_address_crnuce }}</option>
                </select>
                <div class="modal__error-explanation" v-if="showCollectionAddressError">
                  {{ translations.shop.modal.collection_address_missing }}
                </div>
              </div>

              <label class="modal__label">{{ translations.shop.modal.invite_friends }}</label>
              <div class="modal__shop-invite-friend-container" :class="{ 'field_with_errors': isEmailValid === false }">
                <input class="modal__input-field" type="email" v-model="email" :placeholder="translations.shop.modal.invite_email" @input="validateEmail(email)" />
                <div class="big-button" :class="inviteButtonClass" id="shop-invite-firend" @click="inviteFriend">{{ translations.shop.modal.invite_friend }}</div>
              </div>
              <div class="modal__error-explanation" v-if="inviteErrorMessage">
                {{ inviteErrorMessage }}
              </div>

              <div class="modal__shop-invited-friends">
                {{ translations.shop.modal.invited_friends }}: <span class="u-bold">{{ this.invitedFriends.length }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal__section modal__price modal__price--bold">
          {{ translations.shop.modal.postage_costs }}: {{ postageCosts }}€<br/>
          {{ translations.shop.modal.total_price }}: {{ totalPriceText }}
        </div>

        <div class="modal__shop-order-button big-button"
             :class="{ 'big-button--disabled': !isOrderValid }"
             id="shop-place-order"
             @click="placeOrder">
          {{ translations.shop.modal.order }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/variables.scss';
  @import '../styles/modal.scss.erb';

  .modal__form-container {
    position: relative;
  }

  .modal__shopping-cart-button {
    position: relative;
    float: right;
    cursor: pointer;
    width: 35px;
    height: 35px;

    @include respond-to(small) {
      top: -10px;

      @media (orientation: landscape) {
        top: -30px;
      }
    }

    @include respond-to(medium) {
      top: -10px;
    }
  }

  .modal__shopping-cart-items-count {
    position: relative;
    top: -50px;
    left: 25px;
    width: 25px;
    height: 25px;
    color: white;
    background-color: $button_blue;
    border-radius: 100%;
    line-height: 25px;
    font-size: 14px;

    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
  }

  .modal__shopping-cart {
    position: absolute;
    top: 60px;
    right: 20px;
    max-width: 90%;
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .modal__shopping-cart-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;

    &:last-child {
      margin-bottom: 0px;
      border-bottom: none
    }
  }

  .modal__shopping-cart-item-remove {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color: white;
    background-color: $button_blue;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .modal__shop-items {
    display: flex;
    flex-wrap: wrap;
  }

  .modal__shop-item {
    box-sizing: border-box;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    @include respond-to(small) {
      width: 100%;
      padding: 3% 2%;
    }

    @include respond-to(medium) {
      width: 50%;
      padding: 3% 2%;
    }

    @include respond-to(large) {
      width: 25%;
      padding: 3% 5%;
    }
  }

  .modal__shop-item-image-container {
    width: 100%;
  }

  .modal__shop-item-image {
    width: 100%;
    height: 100%;
  }

  .modal__shop-item-sizes {
    display: flex;
    justify-content: center;
  }

  .modal__shop-item-button {
    width: 30px;
    padding: 5px 0;
    margin: 0 5px;
    color: white;
    background-color: $text_grey;
    border-radius: 4px;
    border: 2px solid transparent;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;

   &:hover {
    opacity: 0.8;
   }

   &--selected {
     border: 2px solid black;
   }
  }

  .modal__shop-item-add-button {
    font-size: 13px;
  }

  .modal__price {
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    &--bold {
      font-weight: bold;
    }
  }

  .modal__shop-invite-friend-container {
    display: flex;

    .modal__input-field {
      margin-right: 5px;
    }
  }

  .modal__error-explanation {
    position: relative;
    top: -18px;
    height: 0;
    color: $error_red;
  }
</style>
